import $ from "jquery";
import ui from "static/js/app/ui/ui";
import api from "static/js/app/base/api";
import campaign from "static/js/app/base/campaign";
import events from "static/js/app/base/events";
import storage from "static/js/app/base/storage";
import constants from "static/js/app/base/constants";
import appLanguage from "static/js/app/helpers/language";
import ApiException from "static/js/app/base/exceptions";
import BirthdayPicker from "static/js/app/helpers/birthday-picker";
import { fetchSuggests, getEscoTitle } from "../../base/escoApi";
import language from "../../helpers/language";
import "jquery-ui";
import demo from "../../../../themes/demo/demo";
import * as Sentry from "@sentry/browser";

export default function (cb) {
    events.subscribe.languageUpdated(() => {
        // When we change the language we need to reload data from the BE to update the
        // translations. Simplest way is to do a full page reload
        window.location.reload();
    });

    const comingFromPlayAgainButton =
        storage.pullFlagProfilePageGoesToGames() === true;

    api.getProfile()
        .then((profile) => {
            if (profile) {
                createNewGameBatteryIfNeeded()
                    .then(() => handleProfileForm(cb, profile))
                    .catch((error) => Sentry.captureException(error));
            } else {
                handleProfileForm(cb);
            }
        });

    // hide the header nav menu option
    $("#edit-profile-link").hide();

    demo.styleDemo();

    api.getClientConsents().then((res) => {
        let consentsData = res || [];
        if (consentsData.length > 0) {
            $('#toConsents').hide();
            $('#toGames').show();
        } else {
            $('#toConsents').show();
            $('#toGames').hide();
        }
    });

    // local functions ==============================================

    let profileOptions = {};

    function handleProfileForm(_cb, existingCandidateProfile) {
        api.getCustomProfileForm(
            (res) => {
                generateFormHtml(res);
                loadProfileOptionsAndUpdateFields(
                    existingCandidateProfile,
                    _cb
                );
            },
            // most campaigns don't have custom forms. 404 - load default form
            (err) =>
                loadProfileOptionsAndUpdateFields(existingCandidateProfile, _cb)
        );
        // attach handler that validates fields
        $("#next").on("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            $("#errorMsg").hide();
            var invalid = false;

            // validate each required field
            $(".required").each(function () {
                var $self = $(this);
                $self.closest('.form-group').removeClass('has-error');
                if ($self.val() == '') {
                    // add error if field is empty except for gender if no-gender option is checked
                    if (!($self.hasClass('gender-f') && $('#noGender').is(":checked"))) {
                        $self.closest('.form-group').addClass('has-error');
                        invalid = true;
                    }
                }
            });

            // special validation for birthday
            $('.birthdate-f').css('outline', 'none');
            if ($('#birthdate-f>span .select2-selection__rendered').length && birthdateIsEmpty() && $('.birthdate-f').hasClass('birthday-required')) {
                $('.birthdate-f').css('outline', 'solid 2px #ff004a');
                invalid = true;
            }
            if (invalid) {
                $("#errorMsg").show();
                return;
            }

            ui.startPageLoading();
            submit(existingCandidateProfile);
        });
        demo.demoSwitch();
    }

    function generateFormHtml(res) {
        let form = $(".main-form.complete-form");
        form.html("");
        res.sort(function (a, b) {
            return parseFloat(a.order_num) - parseFloat(b.order_num);
        });

        res.forEach((el) => {
            var required = "";
            if (el.is_mandatory) {
                required = "required";
            }

            switch (el.type) {
                case "LABEL":
                    form.append(
                        '<div class="form-group">' +
                        '<legend class="label-f" data-field-title="' +
                        el.code +
                        '" data-field-id="' +
                        el.id +
                        '">' +
                        (el.text || el.code) +
                        " </legend>" +
                        "</div>"
                    );
                    break;
                case "SELECT":
                    el.options.map(function (o) {
                        o.id = o.code;
                    });
                    profileOptions[el.code] = el.options;
                    form.append(
                        '<div class="form-group">' +
                        "<label>" +
                        (el.text || el.code) +
                        " </label>" +
                        '<select class="select2me ' +
                        required +
                        ' custom-select-f additional-f" ' +
                        'data-field-title="' +
                        el.code +
                        '" data-field-id="' +
                        el.id +
                        '"/>' +
                        "</div>"
                    );
                    break;
                case "FirstName":
                    form.append(
                        '<div class="form-group">' +
                        '<label data-text="name"></label>' +
                        "<input " +
                        required +
                        ' type="text" class="form-control ' +
                        required +
                        ' first-name-f" ' +
                        'data-field-id="' +
                        el.id +
                        '"/>' +
                        "</div>"
                    );
                    break;
                case "LastName":
                    form.append(
                        '<div class="form-group">' +
                        '<label data-text="last_name"></label>' +
                        "<input " +
                        required +
                        ' type="text" class="form-control ' +
                        required +
                        ' last-name-f" ' +
                        'data-field-id="' +
                        el.id +
                        '"/>' +
                        "</div>"
                    );
                    break;
                case "TEXT_FIELD":
                    form.append(
                        '<div class="form-group">' +
                        "<label>" +
                        (el.text || el.code) +
                        " </label>" +
                        "<input " +
                        required +
                        ' type="text" class="form-control ' +
                        required +
                        ' text-field-f additional-f" ' +
                        'data-field-title="' +
                        el.code +
                        '" data-field-id="' +
                        el.id +
                        '"/>' +
                        "</div>"
                    );
                    break;
                case "BINARY_SELECT":
                    form.append(
                        '<div class="form-group">' +
                        "<label>" +
                        (el.text || el.code) +
                        " </label>" +
                        '<select class="select2me ' +
                        required +
                        ' binary-select-f additional-f" ' +
                        'data-field-title="' +
                        el.code +
                        '" data-field-id="' +
                        el.id +
                        '"/>' +
                        "</div>"
                    );
                    break;
                case "BIRTHDATE":
                    form.append(
                        '<div class="form-group birthdate-group">' +
                        '<label data-text="date_of_birth"></label><br/>' +
                        '<div id="birthdate-f" class="form-control birthdate-f input-date birthday-' +
                        required +
                        '">&nbsp;</div>' +
                        "</div>"
                    );
                    break;
                case 'GENDER':
                    form.append('<div class="form-group">' +
                        '<label data-text="plh_gender"></label>' +
                        '<select required class="select2me  gender-f required" placeholder=" " id="gender-required"></select>' +
                        '</div>' +
                        '<div class="form-check checkbox-container checkbox-container-custom" id="gender-form">' +
                        '<input class="form-check-input noGender_checkbox" type="checkbox" id="noGender">' +
                        '<div class="gender-tooltip">' +
                        '<label for="noGender" class="form-check-label" id="checkbox_label" data-text="gender_3_option"></label>' +
                        '<i class="fa fa-question-circle" aria-hidden="true"></i>' +
                        '<span data-text="gender_tooltip" class="tooltip-text"></span>' +
                        '</div>');
                    break;
                case "EDUCATION":
                    form.append(
                        '<div class="form-group-inline">' +
                        '<div class="form-group left-group">' +
                        '<label data-text="plh_educ"></label>' +
                        "<select " +
                        required +
                        ' class="select2me education-f ' +
                        required +
                        '"></select>' +
                        "</div>" +
                        '<div class="form-group middle-group">' +
                        '<label data-text="finished_school"></label>' +
                        "<select " +
                        required +
                        ' class="select2me finished-school-f ' +
                        required +
                        '"></select>' +
                        "</div>" +
                        '<div class="form-group right-group schooldetails">' +
                        '<label id="finishschool" data-text="when_did_finish_school"></label>' +
                        "<select " +
                        required +
                        ' class="select2me schoolGgraduationYear-f"></select>' +
                        "</div></div>"
                    );
                    break;
                case "DEGREE_TYPE":
                    form.append(
                        '<div class="education-group"><div class="form-group">' +
                        '<legend data-text="onderwijsinstelling"></legend>' +
                        '</div><div class="form-group-inline form-row">' +
                        '<div class="form-group">' +
                        '<label data-text="studie"></label>' +
                        '<input type="text" class="form-control study-f ' +
                        required +
                        '">' +
                        '</div><div class="form-group">' +
                        '<label data-text="finished_uni"></label>' +
                        '<select class="select2me finished-uni-f ' +
                        required +
                        '"></select>' +
                        '</div></div><div class="form-group-inline unidetails">' +
                        '<div class="form-group">' +
                        '<label data-text="plh_degree"></label>' +
                        '<select class="select2me degree-f"></select>' +
                        '</div><div class="form-group">' +
                        '<label id="finishuni" data-text="when_did_finish_uni"></label>' +
                        '<select class="select2me graduation-f"></select>' +
                        "</div></div></div>"
                    );
                    break;
                case "OCCUPATION":
                    form.append(
                        ' <div class="occupation-group">' +
                        '<div class="form-group">' +
                        '<legend data-text="occupation"></legend>' +
                        "</div>" +
                        '<div class="form-group-inline form-row job_info">' +
                        ' <div class="form-group" >' +
                        '<label data-text="is_employed" class="have_job_label"></label>' +
                        '<select required class="select2me have_job required" placeholder=" "></select>' +
                        "</div>" +
                        '<div class="form-group job_details">' +
                        '<div class="tooltip_container">' +
                        '<label data-text="job_title" class="job_title">' +
                        "</label>" +
                        '<p data-text="tooltip" class="tooltip-role"></p>' +
                        "</div>" +
                        '<div class="select_job">' +
                        '<input type="text" class="job_selector form-control required" data-text="jobSelection_placeholder" >' +
                        '<div id="someElem"></div>' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        '<div class="api_error" data-text="api_error"></div>' +
                        '<div class="form-check form-check-occupation col-xs-6 checkbox-container">' +
                        '<input class="noOccupation_checkbox" type="checkbox" id="noOccupation">' +
                        '<label for="noOccupation" class="form-check-label " id="checkbox_label" data-text="no_occupation"></label>' +
                        "</div>" +
                        "</div>" +
                        "<div style></div>"
                    );

                    break;
            }
        });

        // add translations to newly created fields
        ui.setPageLang();
    }

    function loadProfileOptionsAndUpdateFields(existingCandidateProfile, _cb) {
        return api
            .getProfileOptions()
            .then(updateUI)
            .then(() => populateFields(existingCandidateProfile))
            .finally(() => _cb());

        function updateUI(data) {
            function translateItems(items) {
                return items
                    .filter(item => item.id !== 'NOT_SPECIFIED')
                    .map(({ id, text }) => ({ id, text: appLanguage.getTranslation(text) }));
            }
            const translatedGenders = translateItems(data.genders);
            const translatedDegrees = translateItems(data.degreeTypes);
            translatedDegrees.unshift(translatedDegrees.pop());
            const translatedFinishedUni = translateItems(data.graduationStages);
            // custom-select-f can be added to the DOM by JS for custom forms
            $(".custom-select-f").each(function () {
                try {
                    $(this)
                        .html("<option></option>")
                        .select2({
                            placeholder: "",
                            data: profileOptions[
                                $(this).attr("data-field-title")
                            ],
                        });
                } catch (e) {
                    console.warn("custom-select-f", e);
                }
            });

            if ($(".birthdate-f").html()) {
                createBirthdayPicker();
            }

            $('.binary-select-f').html('<option></option>').select2({
                placeholder: ' ',
                data: [
                    { id: 0, text: appLanguage.getTranslation('no') },
                    { id: 1, text: appLanguage.getTranslation('yes') }
                ]
            });
            $('.gender-f').html('<option></option>').select2({ placeholder: ' ', data: translatedGenders });
            $('.degree-f').html('<option></option>').select2({ placeholder: ' ', data: translatedDegrees });
            $('.education-f').html('<option></option>').select2({ placeholder: ' ', data: data.educations });
            $('.finished-school-f').html('<option></option>').select2({ placeholder: ' ', data: data.graduationStages });

            var years = [];
            for (var i = 0; i < 45; i++) {
                years.push({
                    id: (new Date().getFullYear() + 3 - i).toString(),
                    text: (new Date().getFullYear() + 3 - i).toString(),
                });
            }

            $(".have_job")
                .html("<option></option>")
                .select2({
                    placeholder: " ",
                    data: [
                        { id: 0, text: appLanguage.getTranslation("no") },
                        { id: 1, text: appLanguage.getTranslation("yes") },
                    ],
                });
            $(".unidetails").hide();

            $(".job_details").hide();
            $(".form-check-occupation").hide();

            $(".have_job").on("select2:select", function (e) {
                var v = e.params.data.id;
                if (v == 0) {
                    $(".job_selector").removeClass("required");
                    $(".job_details").hide();
                    $(".form-check-occupation ").hide();
                    $(".api_error").hide();
                    storage.clearSelectedJobUri();
                    storage.clearEscoCode();
                    storage.clearSelectedJobTitle();
                }
                if (v == 1) {
                    if (storage.getSelectedJobTitle()) {
                        $(".job_selector").val(storage.getSelectedJobTitle());
                    }
                    $(".job_selector").addClass("required");
                    $(".select_job").show();
                    $(".job_details").show();
                    $(".form-check-occupation").show();
                }
            });

            $(".noOccupation_checkbox").on("change", function () {
                if (this.checked) {
                    $(".job_selector").removeClass("required");
                    $(".job_selector").removeAttr("placeholder");
                    $(".job_selector").addClass("disabled");
                    $(".job_selector").val("");
                    $(".api_error").hide();
                    storage.setSelectedJobTitle("not_found");
                    storage.clearSelectedJobUri();
                    storage.clearEscoCode();
                } else if (!this.checked) {
                    $(".job_selector").removeClass("disabled");
                    $(".job_selector").addClass("required");
                    $(".job_selector").attr(
                        "placeholder",
                        appLanguage.getTranslation(
                            $(".job_selector").attr("data-text")
                        )
                    );
                }
            });
            $(".job_selector").attr(
                "placeholder",
                appLanguage.getTranslation($(".job_selector").attr("data-text"))
            );
            $(".api_error").hide();
            $(".job_selector").autocomplete({
                source: function (request, response) {
                    fetchSuggests(
                        response,
                        request.term,
                        language.defineLanguage(),
                        $(".job_selector"),
                        $(".api_error")
                    );
                },
                appendTo: "#someElem",
                autoFocus: true,

                change: function (event, ui) {
                    if (ui.item === null) {
                        $(".job_selector").val("");
                        $(".job_selector").attr("title", "");
                        $(".job_selector").attr(
                            "placeholder",
                            appLanguage.getTranslation(
                                $(".job_selector").attr("data-text")
                            )
                        );
                    } else {
                        $(".job_selector").attr("title", ui.item.label);
                        storage.setSelectedJobTitle(ui.item.title);
                        storage.setSelectedJobUri(ui.item.uri);
                        storage.setEscoCode(ui.item.escoCode)
                    }
                },
            });

            $(".ui-helper-hidden-accessible").remove();

            $(".schoolGgraduationYear-f, .graduation-f")
                .html("<option></option>")
                .select2({ placeholder: " ", data: years });
            $(".finished-uni-f")
                .html("<option></option>")
                .select2({ placeholder: " ", data: translatedFinishedUni });
            $(".finished-school-f").on("select2:select", function (e) {
                $(".schoolGgraduationYear-f").removeClass("required");
                $(".schoolGgraduationYear-f")
                    .closest(".form-group")
                    .removeClass("has-error");
                $("#finishschool").text(
                    appLanguage.getTranslation("when_will_finish_school")
                );
                $(".schooldetails").css("display", "inline-block");
                var v = e.params.data.id;
                if (v === "GRADUATION_IN_PROGRESS") {
                    $("#finishschool").text(
                        appLanguage.getTranslation("when_will_finish_school")
                    );
                    $(".schoolGgraduationYear-f").addClass("required");
                }
                if (v === "GRADUATION_FINISHED") {
                    $("#finishschool").text(
                        appLanguage.getTranslation("when_did_finish_school")
                    );
                    $(".schoolGgraduationYear-f").addClass("required");
                }
                if (v === "GRADUATION_NO") {
                    $("#finishschool").text(" ");
                    $(".schooldetails").css("display", "none");
                }
            });

            $(".finished-uni-f").on("select2:select", function (e) {
                $(".graduation-f").removeClass("required");
                $(".degree-f").removeClass("required");
                $(".graduation-f")
                    .closest(".form-group")
                    .removeClass("has-error");
                $(".degree-f").closest(".form-group").removeClass("has-error");
                $(".unidetails").show();
                $(".graduation-f").attr("disabled", false);
                var v = e.params.data.id;
                if (v === "GRADUATION_IN_PROGRESS") {
                    $("#finishuni").text(
                        appLanguage.getTranslation("when_will_finish_school")
                    );
                }
                if (v === "GRADUATION_FINISHED") {
                    $("#finishuni").text(
                        appLanguage.getTranslation("when_did_finish_school")
                    );
                }
                if (v === "GRADUATION_NO") {
                    $(".unidetails").hide();
                }
            });
            $('#noGender').click(function () {
                checkGender();
            });
            $('#gender-required').on('select2:select', function (e) {
                $("#noGender").prop("checked", false);
            });
        }

        function populateFields(data) {
            let c = storage.getCandidate();
            if (c) {
                $(".first-name-f").val(c.firstName).trigger("change");
                $(".last-name-f").val(c.lastName).trigger("change");
            }
            if (!data) {
                return;
            }
            if (data.additional_fields) {
                for (var i = 0; i < data.additional_fields.length; i++) {
                    $("[data-field-id=" + data.additional_fields[i].id + "]")
                        .val(data.additional_fields[i].value)
                        .trigger("change");
                }
            }
            $(".education-f").val(data.education).trigger("change");
            $(".finished-school-f")
                .val(data.schoolGraduationStage)
                .trigger("change");
            if (data.schoolGraduationStage === "GRADUATION_NO") {
                $("#finishschool").text(" ");
                $(".schoolGgraduationYear-f").removeClass("required");
                $(".schooldetails").css("display", "none");
            } else {
                $(".schooldetails").css("display", "inline-block");
                $(".schoolGgraduationYear-f")
                    .val(data.schoolGraduationYear)
                    .trigger("change");
            }
            $(".study-f").val(data.study).trigger("change");
            $(".finished-uni-f")
                .val(data.universityGraduationStage)
                .trigger("change");
            if (data.universityGraduationStage === "GRADUATION_NO") {
                $(".graduation-f").removeClass("required");
                $(".degree-f").removeClass("required");
                $(".unidetails").hide();
            } else {
                $(".unidetails").show();

                $(".graduation-f").val(data.graduationYear).trigger("change");
            }
            if (data.escoCode) {
                if (data.escoCode == 0) {
                    $(".have_job").val(0).trigger("change");
                    $(".job_selector").removeClass("required");
                } else {
                    $(".have_job").val(1).trigger("change");
                    $(".job_selector").attr(
                        "placeholder",
                        appLanguage.getTranslation("loading")
                    );
                    $(".job_details").show();
                    $(".form-check-occupation").show();
                    storage.setSelectedJobTitle(data.escoTitle);
                    storage.setEscoCode(data.escoCode)
                    storage.setSelectedJobUri(data.escoUri)
                    getEscoTitle(
                        data.escoCode,
                        data.escoUri,
                        language.defineLanguage(),
                        $(".job_selector"),
                        $(".api-error")
                    );
                }
            }
            if (data.escoTitle === "not_found") {
                $(".noOccupation_checkbox").prop("checked", true).trigger("change");
                $(".have_job").val(1).trigger("change");
                $(".select_job").show();
                $(".job_details").show();
                $(".form-check-occupation").show();
            }

            $('.degree-f').val(data.degreeType).trigger('change');

            if (c.gender != 'NOT_SPECIFIED') {
                $('.gender-f').val(c.gender).trigger('change');
            } else {
                $('.noGender_checkbox').prop("checked", true);
            }

            if (c.birthdate) {
                setTimeout(function () {
                    var bdays = c.birthdate.split("-");
                    $(".birthdate-f").attr("data-val", c.birthdate);
                    $(".birthdate-f select")
                        .val(bdays[1].replace(/^0+/, ""))
                        .trigger("change");
                    $(".birthdate-f select:first-of-type")
                        .val(bdays[2].replace(/^0+/, ""))
                        .trigger("change");
                    $(".birthdate-f select:last-of-type")
                        .val(bdays[0].replace(/^0+/, ""))
                        .trigger("change");
                }, 10);
            }
        }
    }

    function submit(profileExists) {
        var data = {
            additional_fields: [],
            client: {
                firstName: $('.first-name-f').val() || null,
                lastName: $('.last-name-f').val() || null,
                birthdate: $('.birthdate-f').attr('data-val') || null,
                gender: $('.gender-f').val() || 'NOT_SPECIFIED' || null,
            },
            degreeType: $(".degree-f").val() || null,
            schoolGraduationYear: $(".schoolGgraduationYear-f").val() || null,
            institution: $(".institution-f").val() || null,
            study: $(".study-f").val() || null,
            education: $(".education-f").val() || null,
            graduationYear: $(".graduation-f").val() || null,
            schoolGraduationStage: $(".finished-school-f").val() || null,
            universityGraduationStage: $(".finished-uni-f").val() || null,
            escoCode: $(".have_job").val() == 0 ? 0 : storage.getEscoCode(),
            escoTitle: storage.getSelectedJobTitle() || null,
            escoUri: storage.getSelectedJobUri() || null,
        };
        $(".additional-f").each(function (el) {
            data.additional_fields.push({
                id: parseInt($(this).attr("data-field-id")),
                value: $(this).val() || null,
            });
        });
        // submit data
        if (profileExists) {
            api.updateProfile(data, afterSubmitOk, onErrorUpdate);
        } else {
            api.createProfile(data, afterSubmitOk, onErrorCreate);
        }
    }

    function afterSubmitOk(res) {
        storage.setCandidate(res.client);
        goToNextPage();
    }

    function onErrorUpdate(err) {
        console.error("Can't update profile information.", err);
    }

    function onErrorCreate(err) {
        // 409 means that the profile already exists.
        // This can happen if the user submits a profile, then navigates back using the browser
        // In such case, we just want to go to the next page and not block the UI
        if (err && err.status === 409) {
            goToNextPage();
            return;
        }
        console.error("Can't create profile information.", err);
    }

    function goToNextPage() {
        let nextPage = "game-types.html";
        if (comingFromPlayAgainButton) {
            nextPage = "introduction-step.html";
        } else if (campaign.isHcm() && !campaign.isDemo()) {
            // HCM campaigns that are not demo, see the consents page
            nextPage = "consents.html";
        }
        events.fire.navigate(nextPage);
    }

    function createBirthdayPicker() {
        // creates and updates the DOM with a new Birthday Picker
        new BirthdayPicker(".birthdate-f", "yyyy-mm-dd");
        // loads translated labels
        setTimeout(function () {
            $("#birthdate-f>span .select2-selection__rendered").text(
                $("#month").text()
            );
            $(
                "#birthdate-f>span:first-of-type .select2-selection__rendered"
            ).html($("#day").html());
            $(
                "#birthdate-f>span:last-of-type .select2-selection__rendered"
            ).html($("#year").html());
            $(".birthdate-group .select2-selection__rendered").click(function (
                el
            ) {
                $(this).html($(this).attr("title"));
            });
        }, 0);
    }

    function birthdateIsEmpty() {
        let day = $('#birthdate-f>span .select2-selection__rendered')[0].textContent
        let month = $('#birthdate-f>span .select2-selection__rendered')[1].textContent
        let year = $('#birthdate-f>span .select2-selection__rendered')[2].textContent

        return isNaN(+day) || isNaN(+month) || isNaN(+year);
    }
}

function createNewGameBatteryIfNeeded() {
    // we only need to create a new game battery when
    // game mode is automatic AND
    // the user already played all the games before (the most recent game battery is completed)
    const campaign = storage.getCampaign();
    const gm = campaign.gameMode;
    if (gm !== constants.gameMode.AUTOMATIC_REPLAY && gm !== constants.gameMode.THRESHOLD_REPLAY) {
        // do nothing
        return Promise.resolve();
    }
    const cid = (storage.getCandidate() || {}).id;
    return api
        .getAllGameBatteries(cid)
        .then((gameBatteries) => {
            // create a new GB -- there are none yet
            if (!gameBatteries || gameBatteries.length === 0) {
                return true;
            }
            
            const sortedByDate = gameBatteries.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            
            const mostRecent = sortedByDate[0];
            // create a new GB -- the most recent GB is completed
            if (mostRecent.completed === true) {
              if (gm === constants.gameMode.THRESHOLD_REPLAY) {
                  return new Date(mostRecent.createdAt) < new Date(campaign.requiredReplayIfOlderThan);
                } else {
                  return true;
                } 
            }
            
            // no need to create a new GB
            return false;
        })
        .then((shouldCreateGb) => {
            if (shouldCreateGb) {
                return api.createNewGameBattery(cid).catch((err) => {
                    throw new ApiException(
                        "Can't create a new game battery for client '" +
                        cid +
                        "'",
                        err
                    );
                });
            }
        });
}

function checkGender() {
    if ($('#noGender').is(':checked')) {
        $("#gender-required").prop('disabled', true);
        $("#select2-gender-required-container").empty();
        $(".gender-f").val('').trigger('change');
    } else {
        $("#gender-required").prop('disabled', false);
    }
}
